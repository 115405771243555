<template>
  <v-navigation-drawer
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
    class="draw-bg"
  >
    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-content>
          <v-img src="hrvec.png" height="120" contain />
        </v-list-item-content>

        <!--     <v-list-item-content class="text_google">
          <v-list-item-title
            class="display-1 text_google"
            v-text="profile.title"
          />
        </v-list-item-content> -->
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
    </v-list>
    <template v-slot:append>
      <base-item
        :item="{
          title: 'เข้าสู่ระบบสำหรับสถานศึกษา',
          icon: 'mdi-package-up',
          to: '/pages/login'
        }"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    ApiKey: "HRvec2021",
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "ข้อมูลทั่วไป",
        to: "/"
      },
      {
        title: "สถานศึกษา",
        icon: "mdi-school",
        to: "/pages/colleges"
      },
      {
        title: "ข่าวประชาสัมพันธ์และคู่มือ",
        icon: "mdi-bell",
        to: "/news"
      },
      {
        title: "เข้าสู่ระบบ",
        icon: "mdi-login",
        to: "/pages/login"
      }

      /*    {
          title: 'Icon',
          icon: 'mdi-information-variant',
          to: '/icon',
        },      */
    ],
    itemsAssistant: [
      {
        title: "เรียกบรรจุครูผู้ช่วย",
        icon: "mdi-login",
        to: "/pages/loginAssTeach"
      }
    ],
    periodassteachs: [],
    periodAssTeachEnable: "1"
  }),
  async mounted() {
    let result_period;
    result_period = await this.$http.post("periodassteach.php", {
      ApiKey: this.ApiKey,
      periodAssTeachEnable: this.periodAssTeachEnable
    });
    this.periodassteachs = result_period.data;
  },

  methods: {},

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: "HRvec IPA"
      };
    }
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      };
    }
  }
};
</script>

<style>
.draw-bg {
  background-color: #787777;
  border-color: #ffffff;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
